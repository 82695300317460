<template>
  <div>
    <v-form
      ref="form"
      class="pb-6 pt-2"
    >
      <v-row>
        <v-col>
          <v-card
            outlined
            class="mt-6"
          >
            <v-card-text>
              <v-row>
                <v-col>
                  <h3 class="mb-2">
                    CGP
                  </h3>
                  <v-card outlined>
                    <v-card-text>
                      <v-select
                        id="bon_id"
                        v-model="adhesion.bon_id"
                        :items="cgpList"
                        item-text="name"
                        item-value="id"
                        label="CGP..."
                        hide-details
                        dense
                        outlined
                        flat
                        solo
                        :loading="loading.cgp"
                        @input="updateCGP"
                      ></v-select>
                    </v-card-text>
                  </v-card>
                  <h3 class="mb-2">
                    Langue
                  </h3>
                  <v-card outlined>
                    <v-card-text>
                      <v-select
                        id="bon_id"
                        v-model="adhesion.lang"
                        :items="languagesList"
                        item-text="label"
                        item-value="name"
                        label="Langue..."
                        hide-details
                        dense
                        outlined
                        flat
                        solo
                        :loading="loading.lang"
                        @input="updateLang"
                      ></v-select>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <h3 class="mb-2">
                    Lettre de mission
                  </h3>
                  <v-card
                    outlined
                    class="active"
                    :class="[{ 'dragover': dragover }, { 'on-error': errors.ldm }]"
                    @drop.prevent="onDrop($event)"
                    @dragover.prevent="dragover = true"
                    @dragenter.prevent="dragover = true"
                    @dragleave.prevent="dragover = false"
                  >
                    <v-card-text>
                      <v-btn
                        outlined
                        :loading="loading.ldm"
                        class="mr-5"
                        @click="selectFile()"
                      >
                        Cliquez ou glissez un fichier
                        <v-icon
                          right
                        >
                          {{ mdiFileUpload }}
                        </v-icon>
                      </v-btn>
                      <v-btn
                        v-if="adhesion.ldm"
                        class="mx-2"
                        fab
                        small
                        outlined
                        color="info"
                        title="Télécharger"
                        :loading="loading.downloadLdm"
                        @click="downloadLdm()"
                      >
                        <v-icon>
                          {{ mdiFileDownload }}
                        </v-icon>
                      </v-btn>

                      <v-btn
                        v-if="adhesion.ldm"
                        class="mx-2"
                        fab
                        small
                        outlined
                        color="error"
                        title="Supprimer"
                        :loading="loading.deleteLdm"
                        @click="deleteDialog = true"
                      >
                        <v-icon>
                          {{ mdiDelete }}
                        </v-icon>
                      </v-btn>

                      <input
                        id="ldm"
                        class="el-upload__input"
                        type="file"
                        name="file"
                        accept="application/pdf"
                        @change="onFileSelected()"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card
            id="adherent"
            class="pb-6"
            :class="{ valid: adherentChecked }"
            outlined
          >
            <v-card-title class="d-flex justify-space-between">
              <div class="text-success">
                Informations adhérent
                <v-icon
                  v-if="adherentChecked"
                  color="success"
                >
                  {{ mdiCheckBold }}
                </v-icon>
              </div>
              <div>
                <v-switch
                  v-model="adherentChecked"
                  :label="(adherentChecked ? 'V' : 'Non v') + 'alidées'"
                  :loading="loading.checked"
                  :disabled="loading.checked"
                  color="success"
                  @click="setChecked"
                ></v-switch>
              </div>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="adherent.statut"
                    :items="[{ value: 'particulier', label: 'Personne physique' }, { value: 'societe', label: 'Personne morale' }]"
                    item-text="label"
                    item-value="value"
                    label="Statut"
                    :rules="[required]"
                    outlined
                    dense
                    hide-details
                    @change="statutChange"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-if="adherent.statut !== 'societe'">
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-select
                    v-model="adherent.civilite"
                    :items="['M.', 'Mme.', 'Mr. & Mme.', 'indivision']"
                    :rules="[requiredWithValidator(adherent.civilite, adherent.statut, 'particulier')]"
                    label="Civilité"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="adherent.nom"
                    label="Nom"
                    :rules="[requiredWithValidator(adherent.nom, adherent.statut, 'particulier')]"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="adherent.prenom"
                    label="Prénom"
                    :rules="[requiredWithValidator(adherent.prenom, adherent.statut, 'particulier')]"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-menu
                    ref="menuref"
                    v-model="datepicker.birthdate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="adherent.date_de_naissance"
                        class="hide-detail"
                        label="Date de naissance"
                        :rules="[dateValidator, requiredWithValidator(adherent.date_de_naissance, adherent.statut, 'particulier')]"
                        v-bind="attrs"
                        outlined
                        dense
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      no-title
                      color="primary"
                      locale="fr-fr"
                      @input="datepicker.birthdate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="adherent.ville_naissance"
                    label="Ville de naissance"
                    :rules="[requiredWithValidator(adherent.ville_naissance, adherent.statut, 'particulier')]"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-select
                    v-model="adherent.nationalite_id"
                    :items="nationalitesOptions"
                    item-text="nom"
                    item-value="id"
                    label="Nationalité"
                    :rules="[requiredWithValidator(adherent.nationalite_id, adherent.statut, 'particulier')]"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="adherent.profession"
                    label="Profession"
                    :rules="[requiredWithValidator(adherent.profession, adherent.statut, 'particulier')]"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="adherent.societe"
                    label="Société ou association"
                    :rules="[requiredWithValidator(adherent.societe, adherent.statut, 'societe')]"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="adherent.siret"
                    label="Numéro de SIRET"
                    :rules="[requiredWithValidator(adherent.siret, adherent.statut, 'societe')]"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="adherent.forme_juridique"
                    label="Forme juridique"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="adherent.adresse1"
                    label="Numéro et libellé de voie"
                    :rules="[required]"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="adherent.adresse2"
                    label="Lieu dit ou service particulier de distribution"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="adherent.codepostal"
                    label="Code postal"
                    :rules="[required]"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="adherent.ville"
                    label="Ville"
                    :rules="[required]"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-select
                    v-model="adherent.code_pays"
                    :items="paysOptions"
                    item-text="pays"
                    item-value="code"
                    label="Pays"
                    :rules="[required]"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="adherent.telephone"
                    label="Téléphone"
                    :rules="[required]"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="adherent.email"
                    label="E-mail"
                    :rules="[required,emailValidator]"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-center">
                  <v-btn
                    color="primary"
                    :loading="loading.adherent"
                    @click="save"
                  >
                    Enregistrer
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-card
                    outlined
                    class="mt-5"
                  >
                    <v-card-title class="d-flex justify-space-between">
                      <div>Personne(s) supplémentaire(s)</div>
                    </v-card-title>
                    <v-card-text>
                      <div v-if="indivisions.length && renderComponent">
                        <Indivision
                          v-for="(indivision, i) in indivisions"
                          :key="i"
                          ref="indivisionComponents"
                          :indivision="indivision"
                          :adherent="adherent"
                          :index="i"
                          @alert="snackbarAlert"
                          @scroll-to-indivision="scrollToIndivision"
                          @delete-indivision="deleteIndivision"
                        />
                      </div>
                      <div
                        v-else
                        class="text-center"
                      >
                        Aucune personne supplémentaire enregistrée
                      </div>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                      <v-btn
                        color="primary"
                        outlined
                        @click="addIndivision"
                      >
                        Ajouter une personne supplémentaire
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card
            outlined
            class="mt-6"
            :class="{ err: errors.fichier_pi, valid: piValidated }"
          >
            <v-card-title class="d-flex align-start pr-7">
              <div class="d-flex align-center">
                <v-avatar
                  size="40"
                  color="secondary"
                  class="v-avatar-light-bg secondary--text me-3"
                >
                  <v-icon
                    size="25"
                    color="primary"
                  >
                    {{ mdiAccountCheckOutline }}
                  </v-icon>
                </v-avatar>

                <div class="text-success">
                  Pièce d'identité
                  <v-icon
                    v-if="piValidated"
                    color="success"
                  >
                    {{ mdiCheckBold }}
                  </v-icon>
                </div>
              </div>
            </v-card-title>
            <v-card-text>
              <v-row class="mb-1">
                <v-col
                  col="12"
                  md="5"
                >
                  <v-select
                    v-model="typePi"
                    :items="['Carte d\'identité recto-verso', 'Passeport', 'Kbis']"
                    label="Type de pièce d'identité"
                    :error="errors.typePi"
                    outlined
                    dense
                    hide-details
                    @change="piChange = true"
                  ></v-select>
                </v-col>
                <v-col
                  col="12"
                  md="5"
                >
                  <v-menu
                    ref="menuDatePi"
                    v-model="datepicker.pi"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="validitePi"
                        class="hide-detail"
                        label="Date de validité"
                        :error="errors.datePi"
                        v-bind="attrs"
                        outlined
                        dense
                        :disabled="true"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="datePi"
                      no-title
                      color="primary"
                      locale="fr-fr"
                      @input="datepicker.pi = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  col="12"
                  md="2"
                >
                  <v-btn
                    color="primary"
                    block
                    :disabled="!piChange"
                    :loading="loading.pi"
                    @click="updatePi"
                  >
                    Valider
                  </v-btn>
                </v-col>
              </v-row>
              <Upload
                ref="pi"
                input-id="piece_ident"
                :document-id="documents.id"
                document-type="fichier_pi"
                :files-validated.sync="piValidated"
              ></Upload>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card
            outlined
            :class="{ err: errors.fichier_kbis, valid: kbisValidated }"
          >
            <v-card-title class="d-flex align-start pr-7">
              <div class="d-flex align-center">
                <v-avatar
                  size="40"
                  color="secondary"
                  class="v-avatar-light-bg secondary--text me-3"
                >
                  <v-icon
                    size="25"
                    color="primary"
                  >
                    {{ mdiDomain }}
                  </v-icon>
                </v-avatar>
                <div class="text-success">
                  Kbis
                  <v-icon
                    v-if="kbisValidated"
                    color="success"
                  >
                    {{ mdiCheckBold }}
                  </v-icon>
                </div>
              </div>
            </v-card-title>
            <v-card-text>
              <Upload
                ref="kbis"
                input-id="kbis"
                :document-id="documents.id"
                document-type="fichier_kbis"
                :files-validated.sync="kbisValidated"
              ></Upload>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card
            outlined
            :class="{ err: errors.fichier_rib, valid: ribValidated }"
          >
            <v-card-title class="d-flex align-start pr-7">
              <div class="d-flex align-center">
                <v-avatar
                  size="40"
                  color="secondary"
                  class="v-avatar-light-bg secondary--text me-3"
                >
                  <v-icon
                    size="25"
                    color="primary"
                  >
                    {{ mdiCreditCardCheckOutline }}
                  </v-icon>
                </v-avatar>
                <div class="text-success">
                  RIB
                  <v-icon
                    v-if="ribValidated"
                    color="success"
                  >
                    {{ mdiCheckBold }}
                  </v-icon>
                </div>
              </div>
            </v-card-title>
            <v-card-text>
              <Upload
                ref="rib"
                input-id="rib"
                :document-id="documents.id"
                document-type="fichier_rib"
                :files-validated.sync="ribValidated"
                :disabled="true"
              ></Upload>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <div class="d-flex justify-end pt-7">
        <v-btn
          color="primary"
          @click="next"
        >
          Suivant
        </v-btn>
      </div>
      <v-snackbar
        v-model="formError"
        :timeout="5000"
        color="error"
        top
      >
        {{ errorText }}
      </v-snackbar>
    </v-form>
    <v-dialog
      v-model="deleteDialog"
      max-width="460"
    >
      <v-card>
        <v-card-title class="text-h5 text-center d-block">
          Supprimer la lettre de mission ?
        </v-card-title>
        <v-card-text>Êtes-vous certain de vouloir supprimer la lettre de mission ? Attention cette action est définitive !</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2"
            color="secondary"
            outlined
            @click="deleteDialog = false"
          >
            Annuler
          </v-btn>
          <v-btn
            color="primary"
            outlined
            @click="deleteLdm()"
          >
            Oui, supprimer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
// import { adherent as adherentData } from '@/store/adherent/data'
import axios from '@axios'
import {
  dateValidator, emailValidator, required, requiredWithValidator,
} from '@core/utils/validation'
import {
  mdiAccountCheckOutline, mdiCalendar, mdiCheckBold, mdiCreditCardCheckOutline, mdiDelete, mdiDomain, mdiFileDownload, mdiFileUpload,
} from '@mdi/js'
import themeConfig from '@themeConfig'
import {
  computed, defineComponent,
  nextTick,
  onMounted, ref, watch,
} from '@vue/composition-api'
import goTo from 'vuetify/lib/services/goto'
import { easeInOutCubic } from 'vuetify/lib/services/goto/easing-patterns'
import { useState } from 'vuex-composition-helpers'
import store from '@/store/adhesion'
import { nationalitesOptions, paysOptions } from '@/data'
import Upload from '@/components/Upload.vue'
import fileDownload from '@/@core/utils/filesDownload'
import { formatDate, getOptionsValue, parseDate } from './adherents'
import Indivision from './components/Indivision.vue'

export default defineComponent({
  components: {
    Upload,
    Indivision,
  },
  props: {
    adhesionId: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      adhesion, adherent, documents, indivisions,
    } = useState('adherent', ['adhesion', 'adherent', 'documents', 'indivisions'])
    let initiated = false
    const renderComponent = ref(true)

    const formError = ref(false)
    const date = ref(new Date().toISOString().substr(0, 10)) // new Date().toISOString().substr(0, 10)
    const datePi = ref(new Date().toISOString().substr(0, 10))

    const form = ref(null)
    const datepicker = ref({
      birthdate: false,
      pi: false,
    })

    const loading = ref({
      checked: false,
      adherent: false,
      pi: false,
      cgp: false,
      lang: false,
      ldm: false,
      downloadLdm: false,
      deleteLdm: false,
    })

    const languagesList = ref([
      { name: 'fr', label: 'Français' },
      { name: 'en', label: 'Anglais' },
      { name: 'de', label: 'Allemand' },
      { name: 'it', label: 'Italien' },
    ])

    const piValidated = ref(false)
    const ribValidated = ref(false)
    const kbisValidated = ref(false)
    const piChange = ref(false)
    const deleteDialog = ref(false)
    const dragover = ref(false)

    const indivisionComponents = ref([])

    const errors = ref({
      typePi: false,
      datePi: false,
      fichier_pi: false,
      fichier_rib: false,
      fichier_kbis: false,
      ldm: false,
    })
    const errorText = ref('')
    const cgp = ref({})
    const cgpList = ref([
      {
        id: 0,
        name: 'Aucun',
      },
    ])

    const validitePi = computed({
      get() {
        return store.state.adherent.documents.validite_pi
      },
      set(val) {
        store.commit('adherent/SET_VALIDITE_PI', val)
      },
    })

    const typePi = computed({
      get() {
        return store.state.adherent.documents.type_pi
      },
      set(val) {
        store.commit('adherent/SET_TYPE_PI', val)
      },
    })

    const adherentChecked = computed({
      get() {
        return store.state.adherent.adhesion.adherent_checked
      },
      set(val) {
        store.commit('adherent/SET_ADHERENT_CHECKED', val)
      },
    })

    const snackbarAlert = alertData => {
      emit('alert', alertData)
    }

    // const piFileValidated = computed(() => store.state.adherent.documents.fichier_pi.every(file => file.validated === true))
    // const ribValidated = computed(() => store.state.adherent.documents.fichier_rib.every(file => file.validated === true))

    // const adhestionData = computed(() => store.getters['adherent/getAdherent'])

    const calculAge = (d = null) => {
      let dateNaissance = null
      let dateModel = null
      if (adherent.value.date_de_naissance !== undefined) {
        dateModel = adherent.value.date_de_naissance
      }
      if (d === null && adherent.value.date_de_naissance !== undefined) {
        if (dateModel === null || !dateModel.includes('/')) {
          dateNaissance = new Date()
        } else {
          const dateEls = dateModel.split('/')
          dateNaissance = new Date(`${dateEls[2]}-${dateEls[1]}-${dateEls[0]}`)
        }
      } else {
        dateNaissance = new Date(d)
      }
      const timeDiff = Date.now() - dateNaissance.getTime()
      const ageDate = new Date(timeDiff)

      return Math.abs(ageDate.getUTCFullYear() - 1970)
    }

    const statutChange = () => {
      let representant = false
      if (adherent.value.statut === 'particulier') {
        representant = calculAge() < 18
      } else {
        representant = true
      }
      emit('representant-legal', representant)
      store.commit('adherent/SET_HAS_REPRESENTANT', representant)
    }

    const next = () => {
      let representant = false
      formError.value = false
      if (adherent.value.statut === 'particulier') {
        representant = calculAge() < 18
      } else {
        representant = true
      }
      emit('representant-legal', representant)

      if (!form.value.validate()) {
        formError.value = true
      } else {
        adherent.value.pays = getOptionsValue(paysOptions, adherent.value.code_pays, 'pays')
        store.commit('adherent/SET_DATA', { prop: 'adhesion', values: adhesion.value })
        store.commit('adherent/SET_DATA', { prop: 'adherent', values: adherent.value })
        emit('next-step', 2)
      }
    }

    watch(adherent, () => {
      if (adherent.value.date_de_naissance !== undefined && adherent.value.date_de_naissance !== null) {
        const dateNaissance = adherent.value.date_de_naissance.split('/')
        date.value = `${dateNaissance[2]}-${dateNaissance[1]}-${dateNaissance[0]}`
      }
    })

    watch(date, () => {
      if (adherent.value.statut === 'particulier') {
        if (!initiated) {
          initiated = true

          return
        }
        const representant = calculAge(date.value) < 18
        adherent.value.date_de_naissance = formatDate(date.value)
        emit('representant-legal', representant)
        store.commit('adherent/SET_HAS_REPRESENTANT', representant)
      }
    })

    watch(datePi, () => {
      validitePi.value = formatDate(datePi.value)
      piChange.value = true
    })

    const setChecked = async () => {
      loading.value.checked = true
      try {
        await store.dispatch('adherent/setAdherentChecked', { id: props.adhesionId, checked: { checked: adherentChecked.value } })
      } catch (e) {
        console.error('error', e)
        snackbarAlert({ type: 'error', msg: e })
      } finally {
        loading.value.checked = false
      }
    }

    const save = async () => {
      loading.value.adherent = true
      try {
        adherent.value.pays = getOptionsValue(paysOptions, adherent.value.code_pays, 'pays')
        await store.dispatch('adherent/updateAdherent', { id: props.adhesionId, data: adherent.value })
        snackbarAlert({ type: 'success', msg: 'Modification de l\'adherent effectuée !' })
      } catch (e) {
        console.error('error', e)
        snackbarAlert({ type: 'error', msg: e })
      } finally {
        loading.value.adherent = false
      }
    }

    const updatePi = async () => {
      loading.value.pi = true
      try {
        await store.dispatch('adherent/updatePi')
        snackbarAlert({ type: 'success', msg: 'Modification de la pièce d\'identité effectuée !' })
        piChange.value = false
      } catch (e) {
        console.error('error', e)
        snackbarAlert({ type: 'error', msg: e })
      } finally {
        loading.value.pi = false
      }
    }

    const updateCGP = async () => {
      loading.value.cgp = true
      try {
        await store.dispatch('adherent/updateCgp', adhesion.value.bon_id)
      } catch (e) {
        console.error('error', e)
        snackbarAlert({ type: 'error', msg: e })
      } finally {
        loading.value.cgp = false
      }
    }

    const updateLang = async () => {
      loading.value.lang = true
      try {
        await store.dispatch('adherent/updateLang', adhesion.value.lang)
      } catch (e) {
        console.error('error', e)
        snackbarAlert({ type: 'error', msg: e })
      } finally {
        loading.value.lang = false
      }
    }

    const selectFile = () => {
      document.getElementById('ldm').click()
    }

    const onFileSelected = async (file = null) => {
      loading.value.ldm = true
      const input = document.getElementById('ldm')
      const formData = new FormData()
      if (file === null) {
        formData.append('file', input.files[0])
      } else {
        formData.append('file', file)
      }
      await axios.post(`adhesions/ldm/${adhesion.value.id}`, formData)
        .then(() => {
          snackbarAlert({ type: 'success', msg: 'Lettre de mission ajoutée !' })
          store.commit('adherent/SET_LDM', 1)
        })
        .catch(() => {
          snackbarAlert({ type: 'error', msg: 'Une erreur est survenue, impossible d\'enregistrer la nouvelle lettre de mission' })
        })
        .finally(() => {
          loading.value.ldm = false
        })
    }

    const downloadLdm = async () => {
      if (loading.value.downloadLdm) {
        return false
      }
      loading.value.downloadLdm = true
      const url = `${themeConfig.app.baseURL}api/adhesions/ldm/download/${adhesion.value.id}`

      let filename = `ldm-${adhesion.value.id}.pdf`
      if (store.state.adherent && store.state.adherent.adhesion && store.state.adherent.adhesion.id) {
        filename = `${store.state.adherent.adhesion.id}_${filename}`
      }

      try {
        await fileDownload(url, filename)
      } catch (e) {
        snackbarAlert({ type: 'error', msg: e })
      } finally {
        loading.value.downloadLdm = false
      }

      return true
    }

    const deleteLdm = async () => {
      if (loading.value.deleteLdm) {
        return false
      }
      deleteDialog.value = false
      loading.value.deleteLdm = true

      await axios.get(`adhesions/ldm/delete/${adhesion.value.id}`)
        .then(() => {
          snackbarAlert({ type: 'success', msg: 'Lettre de mission supprimée !' })
          store.commit('adherent/SET_LDM', 0)
        })
        .catch(() => {
          snackbarAlert({ type: 'error', msg: 'Une erreur est survenue, impossible de supprimer la lettre de mission' })
        })
        .finally(() => {
          loading.value.deleteLdm = false
        })

      return true
    }

    const checkFile = file => {
      const fileTypes = ['image/pdf', 'application/pdf']
      if (!fileTypes.includes(file.type)) {
        snackbarAlert({ type: 'error', msg: `Le fichier ${file.name} n'est pas au bon format (PDF autorisé uniquement).` })

        return false
      }

      return true
    }

    const onDrop = e => {
      dragover.value = false
      errors.value.ldm = false

      let file = null

      if (e.dataTransfer.items.length) {
        file = e.dataTransfer.items[0].getAsFile()
      } else {
        // eslint-disable-next-line prefer-destructuring
        file = e.dataTransfer.files[0]
      }

      if (checkFile(file)) {
        onFileSelected(file)
      }
    }

    const scrollToIndivision = index => {
      const indivisionNum = indivisions.value.length
      let target = '#adherent'

      if (indivisionNum === 1) {
        target = '#indivision-0'
      }
      if (indivisionNum > 1) {
        target = `#indivision-${index}`
      }
      if (indivisionNum > 1 && indivisionNum === index) {
        target = `#indivision-${index - 1}`
      }

      goTo(target, {
        duration: 500,
        easing: easeInOutCubic,
      })
    }

    /* INDIVISIONS */
    const addIndivision = () => {
      store.commit('adherent/ADD_INDIVISION')
    }

    const deleteIndivision = async data => {
      indivisionComponents.value[data.index].loading.delete = true
      try {
        await store.dispatch('adherent/deleteIndivision', { ...data })
        renderComponent.value = false
        await nextTick()
        renderComponent.value = true
        snackbarAlert({ type: 'success', msg: 'suppression effectuée !' })

        setTimeout(() => {
          scrollToIndivision(data.index)
        }, 300)
      } catch (e) {
        console.error('error', e)
        snackbarAlert({ type: 'error', msg: e })
      } finally {
        if (indivisionComponents.value[data.index] !== undefined) {
          indivisionComponents.value[data.index].loading.delete = false
        }
      }
    }

    onMounted(async () => {
      await axios.get('conseillers/list-by-bon-id').then(response => {
        cgp.value = response.data
        Object.keys(response.data).forEach(key => {
          cgpList.value.push({
            id: +key,
            name: response.data[key],
          })
        })
      })
    })

    return {
      form,
      adhesion,
      adherent,
      documents,
      languagesList,
      datepicker,
      mdiCalendar,
      mdiAccountCheckOutline,
      mdiCreditCardCheckOutline,
      mdiCheckBold,
      mdiDomain,
      mdiFileUpload,
      mdiFileDownload,
      mdiDelete,
      date,
      datePi,
      formatDate,
      parseDate,
      statutChange,
      paysOptions,
      nationalitesOptions,
      next,
      formError,
      adherentChecked,
      setChecked,
      loading,
      save,
      errors,
      errorText,
      piChange,
      validitePi,
      typePi,
      updatePi,
      cgpList,
      updateCGP,
      updateLang,
      onFileSelected,
      selectFile,
      downloadLdm,
      deleteLdm,
      deleteDialog,
      onDrop,
      dragover,
      snackbarAlert,

      // piFileValidated,
      ribValidated,
      kbisValidated,
      piValidated,

      // Validation
      required,
      emailValidator,
      dateValidator,
      requiredWithValidator,

      // Indivisions
      indivisions,
      addIndivision,
      scrollToIndivision,
      deleteIndivision,
      indivisionComponents,
      renderComponent,
    }
  },
})
</script>

<style>
.hide-detail .v-input__control .v-text-field__details {
  display: none;
}

.valid {
  border-color: var(--v-success-base)!important;
}

.el-upload__input {
  display: none;
}

.active.dragover {
  border-style: dashed;
  border-color: #b7a088;
}

.on-error {
  border-color: red;
}
</style>
